<template>
  <component
    :is="tag"
    :class="['zlist-group-item', { 'zlist-group-item-action': computedAction, active }]"
  >
    <slot />
  </component>
</template>

<script setup lang="ts">
import { NuxtLink } from '#components'

const props = defineProps<{
  action?: boolean
  active?: boolean
  button?: boolean
}>()

const attrs = useAttrs()
const computedAction = computed(() => {
  if (Boolean(attrs.to) || props.action || Boolean(attrs.href) || props.button) {
    return true
  }

  return false
})

const tag = computed(() => {
  if (props.button) {
    return 'button'
  }
  else if (Boolean(attrs.to) || Boolean(attrs.href)) {
    return NuxtLink
  }

  return 'div'
})
</script>
